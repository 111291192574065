import { handleError } from "@/utils/error";
import { User } from "@/types/user";
import BaseService from "./base-service";

class UsersService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getById(id: string): Promise<User> {
    return await this.get(`${this.getServiceContext}/${id}`);
  }

  public async getUsersInfo(
    userIds: string[],
    fields: string[],
  ): Promise<Partial<User>[]> {
    const params = new URLSearchParams({
      userIds: userIds.join(","),
      fields: fields.join(","),
    });
    const res = await this.get<{ data: Partial<User>[] }>(
      `${this.getServiceContext}/info?${params.toString()}`,
    );
    return res?.data ?? [];
  }
}

export default UsersService;
