import UsersService from "@/services/users-service";
import { User } from "@/types/user";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";

const usersService = new UsersService("users");

export const useUsersStore = defineStore("users", {
  state: () => ({
    users: {} as Record<string, User>,
  }),
  actions: {
    async getById(id: string): Promise<User | null> {
      try {
        if (this.users[id]) {
          return this.users[id];
        }

        const res = await usersService.getById(id);
        this.users[id] = res as User;

        return this.users[id];
      } catch (error) {
        return null;
      }
    },
    async getUsersInfo(
      userIds: string[],
      fields: string[],
    ): Promise<Partial<User>[]> {
      try {
        const res = await usersService.getUsersInfo(userIds, fields);
        return res;
      } catch (e) {
        return [];
      }
    },
  },
});
